<template>
  <div>
    <van-cell-group>
      <van-cell title="卡号:" :value="list.medicalCardNo" />
      <van-cell title="支付类型:" :value="list.costName" />
      <van-cell title="订单号:" :value="list.medTransId" />
      <van-cell title="医疗总费用:" :value="'￥' + list.cost" />
      <van-cell title="统筹基金支付:" :value="'￥' + list.medicalInsurance" />
      <van-cell
        title="个账支付金额:"
        :value="'￥' + list.budgeting"
        v-if="list.payType == '3'"
      />
      <van-cell title="个人现金支付:" :value="'￥' + list.budgeting" v-else />
      <van-cell title="订单状态:" value="缴费异常" />
    </van-cell-group>
    <div class="fuutr">
      <van-button type="primary" block @click="aware">我知道了</van-button>
      <van-button type="primary" block @click="refund">我要退款</van-button>
    </div>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
export default {
  data() {
    return {
      list: {}
    };
  },
  created() {
    let obj = window.localStorage.getItem("item");
    this.list = JSON.parse(obj);
    if (window.localStorage.getItem("refund") == 3) {
      let str = window.location.href;
      this.authCode = this.getQueryStringValue(str, "authCode");
      this.getpayauthno();
    }
  },
  methods: {
    getQueryStringValue(url, key) {
      const match = url.match(new RegExp("[?&]" + key + "=([^&]+)"));
      return match ? match[1] : null;
    },
    aware() {
      this.$router.back();
    },
    refund() {
      window.localStorage.setItem("refund", "3");
      window.localStorage.setItem("outTradeNo", this.list.outTradeNo);

      window.location.href =
        "https://card.wecity.qq.com/oauth/code?authType=2&isDepart=2&bizType=04107&appid=wxcdae494f7e9422e7&cityCode=440781&channel=AAE04h0QalhWndXLQb3mz65H&orgChnlCrtfCodg=BqK1kMStlhVDgN2uHf4EsLK%2FF2LjZPYJ81nK2eYQqxu%2B6IO2JiR4I6RlbboEeY9H&orgCodg=H44078100002&orgAppId=1HFR1294704K75430B0A000040D03343&redirectUrl=http%3A%2F%2Ftssywx.tssrmyy.com%2Fwsyb%2F%23%2Forder%2Foutpatient%2Frefund";
    },
    // 获取医保授权码
    async getpayauthno() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      const data = {
        openId: window.localStorage.getItem("openId"),
        qrcode: this.authCode
      };
      const { data: res } = await jsonPost(
        "wxapp/medicarebills/getpayauthno",
        data
      );

      if (res.code === 0) {
        this.$toast.clear();
        this.payAuthNo = res.pay_auth_no;
        this.medicareRefund();
      } else {
        this.$toast.clear();
        this.$toast({
          title: res.message,
          icon: "none",
          duration: 2500 //持续的时间
        });
      }
    },
    //医保的取消
    async medicareRefund() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const data = {
        outTradeNo: window.localStorage.getItem("outTradeNo"),
        payAuthNo: this.payAuthNo
      };
      const { data: res } = await jsonPost(
        "wxapp/outpatient/OutpatientRefund",
        data
      );
      if (res.code === 0) {
        this.$toast.clear();
        setTimeout(() => {
          this.$router.push({
            path: "/order/outpatient"
          });
        }, 1500);
      } else {
        this.$toast.clear();
        this.$toast.fail(res.msg);
      }
    }
  }
};
</script>

<style lang="less">
page {
  padding-bottom: 30px;
}

.title {
  position: relative;
  margin-top: 50rpx;
  font-size: 60rpx;
  font-weight: 700;
  text-align: center;
  line-height: 100rpx;

  text {
    margin-top: 10rpx;
    text-align: center;
    padding-left: 120rpx;
  }

  .van-icon {
    position: absolute;
    top: 4rpx;
    left: 120rpx;
  }
}

.btn {
  display: flex;
  justify-content: center;
  margin: 30rpx 0;

  .van-button--primary {
    margin-right: 40rpx !important;
  }

  .van-button--info {
    margin-left: 40rpx !important;
  }
}

.tip {
  text-align: center;
}

.code {
  text-align: center;
}

.fuutr {
  .van-button {
    margin-top: 40rpx;
  }
}
</style>
